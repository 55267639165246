import axios from 'axios';
import { getAccessToken } from '../configs/local-storage';
import { API_ROOT } from '../configs/env-vars';
import { logoutUser, showConfirm } from '../utils/helpers';

const client = (headers = {}, customURL?: string) => {
  const service = axios.create({
    baseURL: customURL || API_ROOT, // url of the api
    headers: {
      Authorization: getAccessToken() ? 'Bearer '.concat(getAccessToken()) : '',
      'Content-Type': 'application/json; charset=utf-8',
      'ngrok-skip-browser-warning': '69420',
      ...headers,
    },
  });
  service.interceptors.response.use(
    response => response,
    error => {
      if (getAccessToken() && (error?.status === 401 || error?.response?.status === 401)) {
        logoutUser();
      } else {
        showConfirm({
          okText: 'OK',
          cancelButtonProps: { style: { display: 'none' } },
          content:
            error?.code === 'ERR_NETWORK' ? (
              <>
                Connection to network not available! <br /> Please try again later.
              </>
            ) : (
              error?.message
            ),
        });
      }
      throw error;
    }
  );
  return service;
};

export default client;
