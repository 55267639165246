export const statuses = {
  pending: 'START',
  inService: 'IN SERVICE',
  onHold: 'ON HOLD',
  review: 'REVIEW',
  complete: 'DONE',
};

export const statusParams = {
  [statuses.complete]: {
    serviceComp: 'now',
  },
  [statuses.pending]: {
    serviceStart: null,
    serviceStop: null,
    serviceClose: null,
    serviceComp: null,
  },
  [statuses.inService]: {
    serviceStart: 'now',
    serviceStop: null,
    serviceClose: null,
    serviceComp: null,
  },
  [statuses.onHold]: {
    serviceStop: 'now',
    serviceClose: null,
    serviceComp: null,
  },
  [statuses.review]: {
    serviceClose: 'now',
    serviceComp: null,
  },
};
