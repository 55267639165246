export const BASE_ROOT =
  // process.env.REACT_APP_API_ROOT || 'https://93a4-99-209-56-154.ngrok-free.app/iBrickworxUAT/api';
  process.env.REACT_APP_API_ROOT || 'https://nrx-pdc.medigroup.net/iBrickworxUAT/api';
export const API_ROOT = `${BASE_ROOT}/ServiceTablet`;

export const LOGIN_ROOT = `${BASE_ROOT}/auth/login`;

export const APP_VERSION = process.env.REACT_APP_VERSION ?? '';
export const PDF_VIEWER_SERVICE_URL =
  process.env.REACT_APP_PDF_VIEWER_SERVICE_URL || 'https://pdf.digilabs.ca/api/pdfviewer';

export const SYNCFUSION_KEY =
  process.env.REACT_APP_SYNCFUSION_KEY ||
  'Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH5fcHVVQmZdWUBzW0s=';
