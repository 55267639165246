import styled, { css } from 'styled-components';

interface IMainContentProps {
  back_color?: string;
  padding?: string;
}

const Content = styled.div<IMainContentProps>`
  padding: 20px 30px;
  background-color: #e9ecef;
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
`;

export default Content;
