import { FC, memo } from 'react';
import styled from 'styled-components';
import DashboardHeader from 'components/molecules/dashboard-header';
import { getUserRole } from 'app/services/selector-helpers';
import { useAppSelector } from '../../app/store';
import { IDashboard } from 'interfaces/global-interfaces';

const Wrapper = styled.div`
  padding: 0 10px;

  @media screen and (max-width: 992px) {
    padding: 0 10px;
  }
`;

const DashboardWrapper: FC<IDashboard> = ({ routeData, children }) => {
  const role = useAppSelector(getUserRole);

  return (
    <>
      {role && <DashboardHeader role={role} />}
      <Wrapper>{children}</Wrapper>
    </>
  );
};

export default memo(DashboardWrapper);
