import styled, { css } from 'styled-components';
import { DividerProps, Divider as divider } from 'antd';

interface IMainDividerProps extends DividerProps {
  color?: string;
  margin?: string;
  height?: string;
  width?: string;
}

const Divider = styled(divider)<IMainDividerProps>`
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
    ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
    ${props =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;

export default Divider;
