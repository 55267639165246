export const colors = {
  main_color: '#002885',
  border_color: '#5A5A5A',
  cadet_blue: '#ADB5BD',
  yellow_color: '#FFC000',
  destructive: '#f3301a',
  secondary: '#6a6a6b',
  text_colors: {
    yankees_blue: '#232D42',
    davy_grey: '#54595F',
  },
};
