import styled, { css } from 'styled-components';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';

interface IMainTextAreaProps extends TextAreaProps {
  type?: string;
  mb?: number;
  min_height?: string;
  max_height?: string;
  margin?: string;
  shadow?: string;
  padding?: string;
  height?: string;
  cursor?: string;
  radius?: string;
  border?: string;
}

const TextArea = styled(Input.TextArea)<IMainTextAreaProps>`
  ${props =>
    props.type === 'blue' &&
    css`
      border: 1px solid #000080;
    `}
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.min_height &&
    css`
      min-height: ${props.min_height};
    `}
  ${props =>
    props.max_height &&
    css`
      max-height: ${props.max_height};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow} !important;
    `}
    ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height} !important;
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
`;

export default TextArea;
