import styled, { css } from 'styled-components';
import { Input } from 'antd';
import { PasswordProps } from 'antd/lib/input';

interface IMainPasswordProps extends PasswordProps {
  mb?: number;
  margin?: string;
  padding?: string;
  height?: string;
  cursor?: string;
  radius?: string;
}

const InputPassword = styled(Input.Password)<IMainPasswordProps>`
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
`;

export default InputPassword;
