import { baseQueryWithAuth } from '../../configs/rtk-query';
import { createApi } from '@reduxjs/toolkit/query/react';

export const usersApi = createApi({
  baseQuery: baseQueryWithAuth(),
  reducerPath: 'users',
  endpoints: builder => ({
    getUsers: builder.query({
      query: () => '/users',
    }),
    getLoggedUser: builder.query({
      query: (id: string | number) => `/users/${id}`,
    }),
  }),
});
export const { useGetUsersQuery, useLazyGetUsersQuery, useLazyGetLoggedUserQuery } = usersApi;
