import styled, { css } from 'styled-components';
import { SwitchProps, Switch as toggle } from 'antd';

interface IMainSwitchProps extends SwitchProps {
  mb?: number;
  margin?: string;
  border?: string;
  radius?: string;
  padding?: string;
  width?: string;
  height?: string;
  back_color?: string;
  switched_color?: string;
}

const Switch = styled(toggle)<IMainSwitchProps>`
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color};
    `}
  ${props =>
    props.switched_color &&
    css`
      &.ant-switch-checked {
        background-color: ${props.switched_color} !important;
      }
    `}
`;

export default Switch;
