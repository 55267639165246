import styled, { css } from 'styled-components';
import { FormProps, Form as form } from 'antd';
import { colors } from '../../../constants/colors';

interface IMainFormProps extends FormProps {
  label_height?: string;
  margin?: string;
  width?: string;
  height?: string;
  overflow?: string;
  justify?: string;
  type?: string;
  label_color?: string;
  label_fw?: number;
  active_color?: string;
  fields_radius?: string;
  required_icon_end?: string;
}

const Form = styled(form)<IMainFormProps>`
  .ant-form-item-explain-error {
    font-size: 12px;
    margin-top: 3px;
  }
  ${props =>
    props.label_height &&
    css`
      & .ant-form-item-label,
      & .ant-form-item-label label {
        height: ${props.label_height} !important;
      }
    `}
  ${props =>
    props.label_color &&
    css`
      & .ant-form-item-label,
      & .ant-form-item-label label {
        color: ${props.label_color} !important;
      }
    `}
  ${props =>
    props.label_fw &&
    css`
      & .ant-form-item-label,
      & .ant-form-item-label label {
        font-weight: ${props.label_fw} !important;
      }
    `}
   ${props =>
    props.type === 'popup-form' &&
    css`
      .ant-form-item {
        margin-bottom: 0 !important;
      }
      .ant-form-item-label {
        display: flex !important;
      }
      .ant-form-item-control-input {
        min-height: 30px !important;
      }

      .ant-select-selector,
      .ant-input,
      .ant-picker,
      .ant-input-number-input-wrap {
        box-shadow: 2px 2px 4px 0 #00000040;
        height: 30px !important;
        font-size: 14px;
      }
      .ant-picker-input input {
        font-size: 14px;
      }
      .ant-select-single {
        height: 30px !important;
      }
      .ant-input-number-input,
      .ant-input {
        padding-top: 3px !important;
      }
      .ant-input {
        border: 1px solid ${colors.main_color};
      }

      .ant-input-affix-wrapper {
        padding: 2px 8.8px !important;
        box-shadow: 2px 2px 4px 0 #00000040 !important;
        border: 1px solid #002885 !important;

        & input {
          padding: 0px !important;
          height: fit-content !important;
          border: none !important;
          box-shadow: none !important;
        }
      }

      && .ant-form-item:focus-within .ant-form-item-label > label {
        color: #390f24 !important;
      }
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
  ${props =>
    props.required_icon_end &&
    css`
      label.ant-form-item-required {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        &::before {
          margin: 0 0 5px 4px !important;
        }
      }
    `}
  ${props =>
    props.fields_radius &&
    css`
      & input,
      & textarea,
      & .ant-radio .ant-radio-inner,
      & .ant-input-affix-wrapper:focus,
      & .ant-picker,
      & .ant-input-number,
      & .ant-select-selector,
      & .ant-input-number-affix-wrapper,
      &
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border-radius: ${props.fields_radius} !important;
      }
    `}
  ${props =>
    props.active_color &&
    css`
      & .ant-checkbox-checked .ant-checkbox-inner,
      & .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: ${props.active_color} !important;
        border-color: ${props.active_color} !important;
        box-shadow: none !important;
      }

      & .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover {
        & .ant-checkbox::after {
          border-color: ${props.active_color} !important;
        }
        & .ant-checkbox-inner {
          border-color: ${props.active_color} !important;
          box-shadow: none !important;
        }
      }

      & .ant-input-number {
        .ant-input-number-handler:hover .ant-input-number-handler-down-inner,
        .ant-input-number-handler:hover .ant-input-number-handler-up-inner {
          color: ${props.active_color} !important;
        }
      }
      & .ant-switch {
        border-color: ${props.active_color} !important;
        box-shadow: none !important;
      }
      & .ant-switch-checked {
        background-color: ${props.active_color} !important;
      }
      & .ant-checkbox:not(.ant-checkbox-disabled):focus-within,
      & .ant-checkbox:not(.ant-checkbox-disabled):hover {
        &::after {
          border-color: ${props.active_color} !important;
        }
        .ant-checkbox-inner {
          border-color: ${props.active_color} !important;
          box-shadow: none !important;
        }
      }

      .ant-radio-checked .ant-radio-inner {
        border-color: ${props.active_color} !important;
        &::after {
          background-color: ${props.active_color} !important;
        }
      }
      & input:focus,
      & input:hover,
      & textarea:focus,
      & textarea:hover,
      & .ant-radio:hover .ant-radio-inner,
      & .ant-input-affix-wrapper:focus,
      & .ant-input-affix-wrapper:hover,
      & .ant-picker:not(.ant-picker-status-error),
      & .ant-picker:not(.ant-picker-status-error):focus,
      & .ant-picker:not(.ant-picker-status-error):hover,
      & .ant-input-number:not(.ant-input-number-status-error),
      & .ant-input-number:not(.ant-input-number-status-error):focus,
      & .ant-input-number:not(.ant-input-number-status-error):hover,
      & .ant-select-selector,
      & .ant-select-selector:focus,
      & .ant-input-number-affix-wrapper,
      & .ant-input-number-affix-wrapper:hover,
      & .ant-input-number-affix-wrapper:focus,
      &
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector,
      & .ant-select-selector:hover {
        border-color: ${props.active_color} !important;
      }
      .ant-form-item:focus-within .ant-form-item-label > label {
        color: ${props.active_color} !important;

        &:before {
          color: ${props.active_color} !important;
        }
      }
      & .ant-input-affix-wrapper:focus,
      & .ant-input-affix-wrapper:hover,
      & .ant-input-group:focus-within {
        & .ant-input-suffix {
          border-color: ${props.active_color} !important;
        }
      }

      & .ant-input-group-wrapper:focus,
      & .ant-input-group-wrapper:hover,
      & .ant-input-group:focus-within {
        & .ant-input-group-addon,
        & .ant-input-affix-wrapper,
        & .ant-input-affix-wrapper {
          border-color: ${props.active_color} !important;
        }
      }

      .ant-input-wrapper:hover,
      .ant-input-wrapper:focus,
      .ant-input-group:focus-within {
        .ant-input-group-addon,
        input {
          border-color: ${props.active_color} !important;
        }
      }
      .ant-picker-input {
        & .anticon,
        & svg {
          fill: ${props.active_color} !important;
        }
      }
      .ant-btn:not(.select-action):not([disabled]) {
        background-color: ${props.active_color} !important;
        color: white !important;
      }
    `}
`;

export default Form;
