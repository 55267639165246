import styled, { css } from 'styled-components';
import { TimePickerProps, TimePicker as timePicker } from 'antd';

interface IMainTimePickerProps extends TimePickerProps {
  type?: string;
  mb?: number;
  margin?: string;
  placeholder_color?: string;
  color?: string;
  padding?: string;
  input_padding?: string;
  height?: string;
  width?: string;
  cursor?: string;
  fz?: string;
  fw?: number;
  radius?: string;
}

const TimePicker = styled(timePicker)<IMainTimePickerProps>`
  ${props =>
    props.type === 'blue' &&
    css`
      border: 1px solid #000080;
    `}
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    props.placeholder_color &&
    css`
      .ant-picker::placeholder {
        color: ${props.placeholder_color};
      }
    `}
    ${props =>
    props.color &&
    css`
      .ant-picker {
        color: ${props.color};
      }
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.input_padding &&
    css`
      input {
        padding: ${props.input_padding};
      }
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
      &.ant-picker-group-wrapper > .ant-picker-wrapper {
        height: ${props.height};
        & input {
          height: ${props.height} !important;
        }
      }
    `}
    ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.fz &&
    css`
      input {
        font-size: ${props.fz};
      }
    `}
  ${props =>
    props.fw &&
    css`
      input {
        font-weight: ${props.fw};
      }
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
`;

export default TimePicker;
