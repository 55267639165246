import styled, { css } from 'styled-components';
import { CheckboxProps, Checkbox as checkbox } from 'antd';
import { colors } from 'constants/colors';

interface IMainCheckboxProps extends CheckboxProps {
  size?: number;
  mb?: number;
  margin?: string;
  color?: string;
  direction?: string;
  label_margin?: string;
  fw?: string;
  back_color?: string;
}

const Checkbox = styled(checkbox)<IMainCheckboxProps>`
  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${props => props.back_color || colors.main_color};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.back_color || colors.main_color}!important;
    border-color: ${props => props.back_color || colors.main_color};
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: ${props => props.back_color || colors.main_color}!important;
  }
  .ant-checkbox-checked::after {
    border: 1px solid ${props => props.back_color || colors.main_color};
  }

  ${props =>
    props.size &&
    css`
      input,
      .ant-checkbox-inner {
        width: ${props.size}px;
        height: ${props.size}px;
      }
      .ant-checkbox-inner::after {
        left: ${props.size}%;
      }
    `}
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
  ${props =>
    props.direction &&
    css`
      flex-direction: ${props.direction};
    `}
  ${props =>
    props.label_margin &&
    css`
      > span:last-child {
        margin: ${props.label_margin};
      }
    `}
  ${props =>
    props.fw &&
    css`
      font-weight: ${props.fw};
    `}
`;

export default Checkbox;
