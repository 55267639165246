import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { emptyState, loadState, saveState } from '../../configs/local-storage';

interface IUser {
  user: { [key: string]: any };
  token: string;
  rememberMe?: boolean;
}

const initialState: IUser = loadState() || { user: null, jwt: null };

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, { payload }: PayloadAction<IUser>) {
      state.user = payload?.user;
      state.token = payload?.token;
      saveState(payload, payload?.rememberMe);
    },
    removeUser(state) {
      state.user = {};
      state.token = '';
      emptyState();
    },
  },
});

export const { setUser, removeUser } = userSlice.actions;
export default userSlice.reducer;
