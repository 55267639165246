import { CollapseProps, Collapse as collapse } from 'antd';
import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';

interface IMainCollapseProps extends CollapseProps {
  mb?: number;
  head_mb?: number;
  margin?: string;
  border?: string;
  radius?: string;
  padding?: string;
  width?: string;
  height?: string;
  back_color?: string;
  border_bot?: string;
  border_top?: string;
  title_fz?: string;
  title_padding?: string;
  icon_height?: string;
  hide?: string;
  head_padding?: string;
  arrow_right?: string;
  content_padding?: string;
  header_align?: string;
  type?: string;
}

const Collapse = styled(collapse)<IMainCollapseProps>`
  .ant-collapse-header {
    background: ${colors.main_color};
    display: flex;
    flex-direction: row-reverse;
  }
  ${props =>
    props.type === 'site_home' &&
    css`
      .ant-collapse-header {
        background-color: #002885c4;
        margin-bottom: 10px;
      }

      .ant-collapse-content {
        margin-bottom: 10px;
      }
    `}
  ${props =>
    props.type === 'lot_info' &&
    css`
      .ant-collapse-item {
        margin-bottom: 10px;
        .ant-collapse-header {
          background-color: #e9f2f7;
          color: #002885c4 !important;
          border: 1px solid #002885c4;
          flex-direction: row;
          font-weight: 700;
        }

        .ant-collapse-content {
          margin-bottom: 10px;
          background-color: #f6f6f6;
          padding: 15px;
        }
      }
    `}
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    (props.head_mb ?? props.head_mb === 0) &&
    css`
      .ant-collapse-header {
        margin-bottom: ${props.head_mb}px;
      }
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.back_color &&
    css`
      background-color: ${props.back_color};
    `}
  ${props =>
    props.border_bot &&
    css`
      border-bottom: ${props.border_bot};
    `}
  ${props =>
    props.border_top &&
    css`
      border-top: ${props.border_top};
    `}
  ${props =>
    props.title_fz &&
    css`
      .ant-collapse-header-text {
        font-size: ${props.title_fz};
      }
    `}
  ${props =>
    props.title_padding &&
    css`
      .ant-collapse-header {
        padding: ${props.title_padding}!important;
      }
    `}
  ${props =>
    props.icon_height &&
    css`
      .ant-collapse-expand-icon {
        height: ${props.icon_height}!important;
      }
    `}
  ${props =>
    props.hide === 'arrow' &&
    css`
      .ant-collapse-arrow {
        display: none;
      }
    `}
  ${props =>
    props.head_padding &&
    css`
      .ant-collapse-header {
        padding: ${props.head_padding} !important;
      }
    `}
  ${props =>
    props.arrow_right &&
    css`
      .ant-collapse-arrow {
        right: ${props.arrow_right};
      }
    `}
  ${props =>
    props.content_padding &&
    css`
      .ant-collapse-content-box {
        padding: ${props.content_padding};
      }
    `}
  ${props =>
    props.header_align &&
    css`
      .ant-collapse-header {
        display: flex;
        align-items: ${props.header_align};
      }
    `}
`;

export default Collapse;
