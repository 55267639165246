import React, { Suspense, FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Spin } from 'antd';
import styled from 'styled-components';
import PageContainer, { getPageComponents } from './getPageContainer';
import { getUser, getUserRole } from './app/services/selector-helpers';
import NotFoundPage from './pages/not-found';
import routes from './constants/routes';
import { IRoute } from './interfaces/global-interfaces';
import { useAppSelector } from './app/store';

const PageLoadingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RouteList: FC = () => {
  const role = useAppSelector(getUserRole);
  const user = useAppSelector(getUser);
  const routeList = user?.userId ? getPageComponents(role) : routes;

  return (
    <Routes>
      {Object.values(routeList)
        .filter((el: any) => el.pathname)
        .map((route: IRoute) => (
          <Route
            path={route.pathname}
            key={route.pathname}
            element={
              <Suspense
                fallback={
                  <PageLoadingWrapper>
                    <Spin spinning={true} size='large' />
                  </PageLoadingWrapper>
                }
              >
                <PageContainer route={route} />
              </Suspense>
            }
          />
        ))}
      <Route path='*' key='not-found' element={<NotFoundPage />} />
    </Routes>
  );
};

export default RouteList;
