import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './assets/global.scss';
import { Provider } from 'react-redux';
import { registerLicense } from '@syncfusion/ej2-base';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
import { SYNCFUSION_KEY } from './configs/env-vars';

registerLicense(SYNCFUSION_KEY);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
