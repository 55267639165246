import styled, { css } from 'styled-components';
import { DatePicker as datepicker } from 'antd';
import { colors } from 'constants/colors';
import type { DatePickerProps } from 'antd';

interface CustomDatePickerProps {
  mb?: number;
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  cursor?: string;
  radius?: string;
}

type IMainDatePickerProps = CustomDatePickerProps & DatePickerProps;

const DatePicker = styled(datepicker)<IMainDatePickerProps>`
  & .ant-picker-suffix {
    color: ${colors.main_color};
  }
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
`;

export default DatePicker;
