import styled, { css } from 'styled-components';
import ReactDOM from 'react-dom';
import { Form } from '../index';
import { FC, ReactNode } from 'react';

interface IModalWrapper {
  children: ReactNode;
  closeModal?: () => void;
  height?: string;
  padding?: string;
}

const ModalWrapperComp = styled.div<{ height?: string; padding?: string }>`
  position: fixed;
  width: 100vw;
  height: calc(100% - 45px);
  top: 0;
  left: 0;
  display: flex;
  align-items: safe center;
  justify-content: safe center;
  background-color: rgba(8, 26, 51, 0.8);
  z-index: 102;
  overflow-y: auto;
  padding: 30px 0 15px;

  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
`;

const modalRoot = document.getElementById('modal-root') as HTMLElement;

const ModalWrapper: FC<IModalWrapper> = ({ children, height, padding }) => {
  // const handleClick = event => {
  //   if (event.target === event.currentTarget) {
  //     event.stopPropagation();
  //     closeModal();
  //   }
  // };
  return ReactDOM.createPortal(
    // <ModalWrapperComp onMouseDownCapture={handleClick}>
    <ModalWrapperComp height={height} padding={padding}>
      <Form.Provider onFormChange={() => {}}>{children}</Form.Provider>
    </ModalWrapperComp>,
    modalRoot
  );
};

export default ModalWrapper;
