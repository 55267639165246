import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Paragraph, Row } from 'components/atoms';
import { APP_VERSION } from 'configs/env-vars';
import { removeUser } from 'app/slices/user';
import { showConfirm } from 'utils/helpers';
import { colors } from 'constants/colors';
import { useAppSelector } from 'app/store';
import { getUser } from 'app/services/selector-helpers';
import { Modal } from 'antd';
import { useEffect } from 'react';
import styled from 'styled-components';

const HeaderWrapper = styled(Row)`
  padding: 5px 20px;

  @media screen and (max-width: 992px) {
    padding: 5px 20px;
  }
`;

const DashboardHeader = ({ role }: { role: string }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(getUser);

  useEffect(() => {
    const dateContainer = document.getElementById('date-container');
    const timeContainer = document.getElementById('time-container');
    const myInterval = setInterval(() => {
      if (dateContainer && timeContainer) {
        dateContainer.innerHTML = moment().format('ll');
        timeContainer.innerHTML = moment().format('dddd, h:mm A');
      }
    }, 5 * 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, []);

  const handleLogout = () => {
    showConfirm({
      content: 'Are you sure you want to log out and exit?',
      onCancel: () => Modal.destroyAll(),
      okText: 'No',
      cancelText: 'Yes',
      cancelButtonProps: {
        onClick: () => {
          dispatch(removeUser());
          sessionStorage.removeItem('siteFilters');
          navigate('/sign-in');
          Modal.destroyAll();
        },
      },
    });
  };

  return (
    <HeaderWrapper justify='space-between' align='middle' back_color={colors.main_color}>
      <Col height='100%' align='start'>
        <Row gutter={[10, 5]} height='100%'>
          <Col span={24}>
            <Paragraph fw={700} fz={14} color='#fff'>
              {user?.name || '-'}
            </Paragraph>
          </Col>
          <Col span={24}>
            <Paragraph fw={700} fz={14} color='#fff'>
              {user?.userName || '-'}
            </Paragraph>
          </Col>
        </Row>
      </Col>
      <Col height='100%' align='center'>
        <Row gutter={[10, 5]} height='100%'>
          <Col span={24}>
            <Paragraph fw={700} fz={14} color='#fff' id='date-container'>
              {moment().format('ll')}
            </Paragraph>
          </Col>
          <Col span={24}>
            <Paragraph fw={700} fz={14} color='#fff' id='time-container'>
              {moment().format('LT')}
            </Paragraph>
          </Col>
        </Row>
      </Col>
      <Col height='100%' align='end'>
        <Row gutter={[10, 5]} height='100%'>
          <Col span={24}>
            <Paragraph fw={700} fz={14} color='#fff' onClick={handleLogout} cursor='pointer'>
              Sign out
            </Paragraph>
          </Col>
          <Col span={24}>
            <Paragraph fw={700} fz={14} color='#fff'>
              {APP_VERSION || 'v23.1211.0839'}
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </HeaderWrapper>
  );
};

export default DashboardHeader;
