import styled, { css } from 'styled-components';
import Icon from '@ant-design/icons';
import { CSSProperties, MouseEventHandler } from 'react';

interface IIconProps {
  className?: string;
  onClick?: MouseEventHandler<SVGSVGElement>;
  style?: CSSProperties;
  color?: string;
  width?: string;
  height?: string;
}

const IconWrapper = styled(Icon)<IIconProps>`
  ${props =>
    props.color &&
    css`
      svg path {
        fill: ${props.color};
        stroke: ${props.color};
      }
    `}
  ${props =>
    props.width &&
    css`
      svg {
        width: ${props.width};
      }
    `}
      ${props =>
    props.height &&
    css`
      svg {
        height: ${props.height};
      }
    `}
`;

export default IconWrapper;
