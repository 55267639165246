import React, { FC, memo, ReactElement } from 'react';
import styled from 'styled-components';

const GuestWrapperComp = styled.div``;
interface IGuestWrapper {
  children?: ReactElement;
}
const GuestWrapper: FC<IGuestWrapper> = ({ children }) => {
  return <GuestWrapperComp>{children}</GuestWrapperComp>;
};

export default memo(GuestWrapper);
