import styled, { css } from 'styled-components';
import { InputNumberProps, InputNumber as inputNumber } from 'antd';

interface IMainInputNumberProps extends InputNumberProps {
  color_type?: string;
  mb?: number;
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  cursor?: string;
  radius?: string;
  shadow?: string;
  text_align?: string;
  hide?: string;
  border_color?: string;
  arrows?: string;
  arrow_color?: string;
}

const InputNumber = styled(inputNumber)<IMainInputNumberProps>`
  ${props =>
    (props.mb ?? props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    props.shadow &&
    css`
      box-shadow: ${props.shadow} !important;
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${props =>
    props.arrows === 'visible' &&
    css`
      & .ant-input-number-handler-wrap {
        opacity: 1;
      }
    `}
  ${props =>
    props.arrow_color &&
    css`
      & .ant-input-number-handler-wrap span {
        color: ${props.arrow_color}!important;
      }
    `}
  ${props =>
    props.text_align &&
    css`
      input {
        text-align: ${props.text_align};
      }
    `}
  ${props =>
    props.border_color &&
    css`
      border: 1px solid ${props.border_color}!important;
    `}
`;

export default InputNumber;
